/* src/styles/SgamPage.module.css */

/* Page Container */
.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  background-image: url('../../../../assets/Wallpaper_bw.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-blend-mode: overlay;
  overflow: hidden;
}

/* Main Content Styles */
.content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px;
  overflow: hidden;
  border-radius: 15px;
}

/* Container holding map */
.container {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative; /* Ensure positioning context for absolute children */
}

/* Map Container */
.mapContainer {
  flex: 1;
  position: relative; /* For absolute positioning of overlays */
  height: 100%;
  width: 100%;
  border: 4px solid var(--third-color);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  overflow: hidden;
  z-index: 1;
}

/* Leaflet Map Styles */
#map, .map {
  width: 100%;
  height: 100%;
}

/* Text Content Overlay */
.textContentOverlay {
  position: absolute;
  top: 5px;
  left: 10px; /* Position next to control buttons */
  background: var(--third-color); /* Slightly more opaque for better readability */
  padding-right: 20px;
  /* margin-right: 20px; */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Increased z-index to appear above mapControls */
  max-width: 300px;
}

.textContentOverlay h1 {
  display: flex;
  align-items: center;
  font-size: 2em;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 10px;
}

.textContentOverlay p {
  color: #fff;
  margin: 0;
}

.projectIcon{
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 10px;
}
/* Loading Spinner Styles */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error Message Styles */
.errorMessage {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  z-index: 101;
}

.errorCloseButton {
  float: right;
  padding: 0px 4px;
  background-color: white;
  color: grey;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  margin-top: 5px;
}

.errorCloseButton:hover {
  background-color: #0056b3;
}

/* Popup Content Styles */
.popup-content {
  font-family: Arial, sans-serif;
  background: white;
  padding: 10px;
  border-radius: 5px;
}

.popup-content h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
}

.hazard-item {
  margin-bottom: 5px;
}

.hazard-level {
  display: inline-block;
  width: 60px;
  text-align: center;
  padding: 2px 4px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

/* Legend and Feature Selector Styles */
.info.legend, .info.feature-selector {
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  margin-bottom: 5px;
}

.feature-selector label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

/* Dropdown Control Styles */
.dropdown-control {
  background: white;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

.featureSelect {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Footer Styles */
.footer {
  background-color: rgba(51, 51, 51, 0.5);
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

/* Refresh Button Styles */
.refreshButton {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.refreshButton:hover {
  background-color: #2980b9;
}

/* GeoJSON Info Styles */
.geojsonInfo {
  margin-top: 10px;
  background-color: #e2e3e5;
  padding: 10px;
  border-radius: 4px;
}

/* Map Controls Container */
.mapControls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px; /* Space between buttons */
  z-index: 1000; /* Ensure buttons are above the map */
}

/* Control Button Container */
.controlButtonContainer {
  position: relative;
}

/* Back Button Styles */
.backButton {
  background-color: var(--third-color);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-duration) ease;
}

.backButton:hover {
  background-color: var(--fourth-color);
}

/* Settings Button Styles */
.settingsButton {
  background-color: var(--third-color);
  border: none;
  padding: 12px;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.settingsButton:hover {
  background-color: var(--fourth-color);
}

.featureButton {
  background-color: var(--third-color);
  color: #ffffff;
  border: none;
  padding: 12px;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.featureButton:hover {
  background-color: var(--fourth-color);
}

/* Popup Styles */
.popup {
  position: absolute;
  top: 50px; /* Adjust as needed */
  right: 0;
  z-index: 1002; /* Above mapControls and text overlay */
}

/* Popup Container Styles */
.popupContainer {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 250px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1002; /* Above mapControls and text overlay */
}

.popupContainer h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
  color: #333;
}

.popupContainer > :global(*) {
  margin-bottom: 12px;
}

.popupContainer > :global(*)::last-child {
  margin-bottom: 0;
}

.customControls {
  position: absolute;
  bottom: 20px; /* Distance from the bottom */
  left: 20px;   /* Distance from the left */
  display: flex;
  flex-direction: column;
  gap: 10px;    /* Space between buttons */
  z-index: 1;   /* Ensure it appears above other map elements */
}

.controlButton {
  background: rgb(204, 23, 23);
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
}

.controlButton:hover {
  background: #f0f0f0;
}

.controlLabel3d {
  font-family: Arial, sans-serif;
  font-size: 14px; /* You can adjust the size */
  color: #ffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 1); /* Adds a subtle shadow to the text */
  text-decoration: solid;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  margin-left: 35px;
  margin-bottom: 0px;
}

.controlLabel3d input[type="checkbox"] {
  margin-right: 5px !important; /* Forces the margin */
}

/* Responsive Styles */

/* Media Query for larger screens */
/* @media (min-width: 769px) {
  .container {
    flex-direction: row;
  }
}*/

/* Media Query for smaller screens */
/*@media (max-width: 768px) {
  .container {
    flex-direction: column;
    position: relative;
  }

  .mapContainer {
    flex: none;
    width: 100%;
    height: 80vh;
    margin-top: 20px;
    z-index: 1;
    opacity: 1;
    position: relative;
  }

  .mapControls {
    top: 10px;
    right: 10px;
    gap: 6px;
  }

  .backButton {
    padding: 8px 16px;
    font-size: 14px;
  }*/

  /*.textContentOverlay {
    max-width: 90%;
    left: 10px;
    top: 60px; /* Adjust if necessary */
  /* }
} */

.pegmanContainer {
  position: absolute;
  bottom: 100px;
  left: 200px;
  z-index: 1000;
  user-select: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.pegmanContainer.dragging {
  cursor: grabbing;
}

.pegmanImage {
  width: 25px;
  height: 25px;
}

.streetViewModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.streetViewContent {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff5f5f;
  border: none;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 10;
}

/* Additional Media Queries for very small screens */
@media (max-width: 480px) {
  .textContentOverlay {
    padding: 10px 15px;
    max-width: 100%;
    left: 5px;
    right: 5px;
    top: 50px; /* Adjust based on controls */
  }

  .backButton {
    padding: 6px 12px;
    font-size: 12px;
  }

  .textContentOverlay h1 {
    font-size: 1.5em;
  }

  .settingsButton {
    padding: 8px;
  }
}


.basemapSelector {
  margin-bottom: 20px;
  margin-left: 30px;
  font-family: Arial, sans-serif; /* Change to your preferred font */
  font-size: 14px; /* Adjust font size */
  color: #333; /* Text color */
  background-color: #f9f9f9; /* Background color */
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Rounded corners */
  padding: 8px 12px; /* Padding to make it look more spacious */
  padding-right: 30px;
  outline: none; /* Remove default outline */
  cursor: pointer; /* Cursor change on hover */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  appearance: none; /* Remove the default browser appearance */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 4 5"><path fill="gray" d="M2 0L0 2h4z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;
}

.basemapSelector:hover {
  background-color: #e9e9e9; /* Change background on hover */
}

.basemapSelector:focus {
  border-color: #007bff; /* Highlight border on focus */
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5); /* Add glow effect on focus */
}

/* .basemapSelector:disabled {
  background-color: #f0f0f0; 
  cursor: not-allowed; 
} */


.settingsPopup {
  position: absolute;
  top: 60px; /* Adjust based on the settings button's position */
  left: 20px; /* Adjust based on the settings button's position */
  z-index: 1000; /* Ensure it appears above the map and other controls */
}

/* .streetViewPopup {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modalContent {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
} */

.closeStreetViewButton {
  position: absolute;
  top: 11px;
  right: 60px;
  background: #434343;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1002;
  border: none;
  color: #b4b4b4;
  padding: 9px 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 10;
}

.closeStreetViewButton:hover {
  color: #ededed;
}


/* Additional Styling for Better Accessibility and UI */

/* Ensure popups have higher z-index */

/* src/components/Dashboard.module.css */

/* Header Section */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.title {
    font-size: 2rem;
    color: var(--primary-color);
    margin: 0;
}

.logoutButton {
    background-color: var(--third-color);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration) ease;
}

.logoutButton:hover {
    background-color: var(--fourth-color); /* Adjusted hover color for better contrast */
}

/* Admin Link Section */
.adminLinkContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.adminLink {
    font-weight: bold;
    color: var(--third-color);
    padding: 10px 20px;
    border: 2px solid var(--third-color);
    border-radius: var(--border-radius);
    text-decoration: none;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
}

.adminLink:hover {
    background-color: var(--third-color);
    color: #ffffff;
    transform: translateY(-3px);
}

/* Subtitle */
.subtitle {
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-color);
}

/* Services Section */
.services {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.serviceButton {
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    padding: 35px 25px;
    border-radius: var(--border-radius);
    cursor: pointer;
    flex: 1 1 200px;
    text-align: center;
    max-width: 300px;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
}

.serviceButton:hover {
    background-color: var(--secondary-color); /* Adjusted hover color for better contrast */
    transform: translateY(-5px);
}

/* Error and Loading Messages */
.error {
    color: var(--danger-color);
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

.loading {
    text-align: center;
    font-size: 1.2rem;
    color: var(--text-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 20px;
    }

    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .logoutButton {
        width: 100%;
        margin-top: 10px;
    }

    .adminLinkContainer {
        margin-bottom: 15px;
    }

    .adminLink {
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .serviceButton {
        padding: 12px 18px;
        font-size: 0.9rem;
    }

    .adminLink {
        padding: 6px 12px;
        font-size: 0.9rem;
    }
}

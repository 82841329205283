/* src/features/projects/sgam/styles/Legend.module.css */

.legend {
  position: absolute;
  bottom: 30px;
  right: 10px;
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.3);
  font-size: 12px;
  z-index: 1;
}

.legend h4 {
  margin: 0 0 5px 0;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.legendColor {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border: 1px solid #ccc;
  display: inline-block;
}

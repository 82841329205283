/* src/components/Layout.module.css */

/* Wrapper to center the container */
.wrapper {
    display: flex;  
    /* flex-direction: column; */
    /* flex-wrap: nowrap;              */
    justify-content: center;      /* Horizontally center the container */
    align-items: center;          /* Vertically center the container */
    min-height: 100vh;            /* Ensure the wrapper takes up the full viewport height */
    /* width: 100%;                   */
}

/* Container to hold your content */
.container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: var(--border-radius, 8px); /* Fallback value if the variable isn't set */
    box-shadow: var(--box-shadow, 0 4px 6px rgba(0, 0, 0, 0.1)); /* Fallback value */
    max-width: 90%;
    width: auto;                   
    margin: 0;                     /* Removes any default margins */
    box-sizing: border-box;        /* Ensures padding and borders are included in the width and height */
    transition: transform var(--transition-duration, 0.3s) ease; /* Fallback duration */
    flex-wrap: nowrap;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .container {
        padding: 20px;
        max-width: 600px;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 15px;
        max-width: 90%;
    }
}

/* src/components/ProjectDetail.module.css */

.container {
    background-color: #ffffff;
    padding: 10px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px); /* Full viewport height minus margins */
    max-width: calc(100vw - 20px); /* Full viewport width minus margins */
    min-height: 450px;
    overflow: hidden; /* Prevent content overflow */
    position: relative;
    aspect-ratio: var(--aspect-ratio, 16 / 9);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Reduced margin for compactness */
}

.title {
    text-align: left;
    font-size: 1.8rem; /* Slightly reduced font size */
    color: var(--primary-color);
    flex: 1; /* Allow title to take available space */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.backButton {
    background-color: var(--third-color);
    color: #ffffff;
    border: none;
    padding: 8px 16px; /* Reduced padding */
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration) ease;
    margin-left: 10px; /* Space between title and button */
}

.backButton:hover {
    background-color: var(--fourth-color); /* Adjusted hover color for better contrast */
}

.landslideFilterContainer {
    display: flex;
    align-items: center; /* Align items vertically centered */
    gap: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.controlsGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.controlRow {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1; /* Allows the filters to grow evenly */
}

.filterLabel {
    min-width: 50px;
    font-weight: 600;
    color: #333;
    font-size: 0.95rem;
}

.filterSelect {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: white;
    color: #333;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.filterSelect:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.colorBarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 8px;
    height: fit-content;
    flex-grow: 0; /* Prevent the color bar from expanding */
}


.resetButton {
    padding: 6px 10px; /* Reduced padding */
    background-color: rgba(74, 144, 226, 0.8); /*var(--third-color);*/
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.95rem; /* Consistent font size */
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
}

.resetButton:hover {
    /* background-color: var(--fourth-color); */
    background-color: rgba(74, 144, 226, 1);
}


.imageViewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto; /* Allows growth and shrink based on available space */
    overflow: hidden; /* Keep as hidden to manage internal overflow */
}

.imageContainer {
    width: 100%;
    height: 100%;
    flex: 1 1 auto; /* Allows the container to grow and shrink */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; /* Keeps the image contained */
    margin-bottom: 0px;
    position: relative;
    border-radius: var(--border-radius);
}

.displayedImage {
    max-width: 100%;
    max-height: 100%;
    width: auto; /* Maintains aspect ratio */
    height: auto; /* Maintains aspect ratio */
    object-fit: contain;
    cursor: grab;
    border-radius: var(--border-radius); 
}

.displayedImage:active {
    cursor: grabbing;
}

.sliderContainer {
    width: 100%;
    text-align: center;
    margin-bottom: 10px; /* Reduced margin */
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px; /* Increased gap for better spacing */
    justify-content: space-between; /* Ensure space between slider and metadata */
}

.slider {
    /* max-width: 70%; */
    flex-grow: 1;
    margin-bottom: 0;
    color: var(--third-color);
}

.tunnelMetadata {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 15px; /* Adjusted gap for alignment */
    flex-wrap: nowrap; /* Prevent wrapping to keep metadata on the same row as slider */
    font-size: 0.95rem; /* Consistent font size */
    overflow: auto; /* Hide overflow to maintain layout */
    width: 35%; /* Fixed width for metadata */
    flex-shrink: 0; /* Prevents shrinking to allow visibility */
}

.timestampContainer {
    display: flex;
    justify-content: center;
    gap: 10px; /* Reduced gap */
    flex-wrap: nowrap; 
    /* min-width: 40%; */
}

.transparentBox {
    background-color: transparent;
    padding: 8px 10px; /* Reduced padding */
    border-radius: 6px; /* Slightly reduced border radius */
    text-align: center;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timestampBox {
    background-color: #f0f4f8;
    padding: 8px 10px; /* Reduced padding */
    border-radius: 6px; /* Slightly reduced border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconLabelRow {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Adds space between icon and label */
}

.timestampLabel {
    display: block;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0px; /* Reduced margin */
    font-size: 0.75rem; /* Slightly smaller font */
}

.timestampValue {
    font-size: 0.85rem; /* Consistent font size */
    color: #555555;
}

.icon {
    font-size: 0.85rem; /* Reduced font size */
    color: var(--primary-color);
    margin-bottom: 4px; /* Reduced margin */
}

.error {
    color: var(--error-color);
    text-align: center;
    font-weight: bold;
    margin-top: 10px; /* Added margin */
}

.loading {
    text-align: center;
    font-size: 1rem; /* Reduced font size */
    color: var(--text-color);
    margin-top: 10px; /* Added margin */
}

.noImages {
    text-align: center;
    font-size: 0.95rem; /* Consistent font size */
    color: var(--text-color);
    margin-top: 10px; /* Added margin */
}

/* Optional Zoom Controls */
.controls {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 5px;
    z-index: 15; /* Ensure controls are above all canvases */
}

.controlButton {
    background-color: rgba(74, 144, 226, 0.75); /* Semi-transparent button */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease;
}

.controlButton:hover {
    background-color: rgba(74, 144, 226, 1);
}

/* Static Canvas Styling */
.staticCanvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none; /* Prevents canvas from capturing mouse events */
    width: 100%;
    height: 99%;
    z-index: 5; /* Below highlight canvas */
}

/* Highlight Canvas Styling */
.highlightCanvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: all; /* Allows capturing mouse events */
    width: 100%;
    height: 99%;
    z-index: 10; /* Above static canvas */
}

.keyboardHint {
    margin-bottom: 0px;
}

/* Popup Overlay */
/* .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
} */

/* Outer Container for Popups */
/* src/components/ProjectDetail.module.css */


/* Popup Overlay */
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none; /* Allows clicks to pass through except for popups */
    z-index: 1000; /* Ensures popups appear above other elements */
    display: flex; /* Flex to align popups */
    justify-content: center;
    align-items: center;
}

/* Draggable and Resizable Popup */
.draggablePopup {
    pointer-events: auto; /* Allows interaction */
}

/* Popup Content */
.popupContent {
    pointer-events: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: var(--border-radius);
    position: relative; /* Changed from absolute to relative */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: visible; /* Ensure resize handles are visible */
}

/* Popup Header */
.popupHeader {
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the drag handle */
    position: relative;
}

/* Define the drag handle */
.dragHandle {
    cursor: move; /* Indicate draggable area */
    user-select: none; /* Prevent text selection during drag */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the title within the drag handle */
    width: 100%; /* Ensures the drag handle takes full width for better touch areas */
}

/* Close Button */
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #888;
    z-index: 10; /* Ensure it stays on top */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
}

.closeButton:hover {
    color: #000;
}

/* Popup Chart */
.popupChart {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensures it takes full height of the popup content */
    width: 100%;  /* Ensures it takes full width of the popup content */
    overflow: hidden; /* Prevents overflow */
}

/* Spinner Styles */
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto;
}

.doubleBounce1, .doubleBounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #71a4da; /* You can customize the color */
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: bounce 2.0s infinite ease-in-out;
    animation: bounce 2.0s infinite ease-in-out;
}

.doubleBounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes bounce {
    0%, 100% {
        transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
    }
}

@keyframes bounce {
    0%, 100% {
        transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
    }
}


.colorBarTitle {
    font-size: 0.95rem;
    font-weight: 500;
    margin-bottom: 5px;
    color: #333;
    text-align: center;
}

.colorBar {
    display: flex;
    height: 25px;
    width: 200px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.colorSegment {
    flex: 1;
    height: 100%;
}

.colorBarLabels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
    font-size: 0.85rem;
    color: #666;
    padding: 0 5px;
}

.colorBarInputs {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    width: 200px;  /* Match colorbar width */
}

.displacementInput {
    width: 80px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}
/* Rest of the previous styles remain unchanged */

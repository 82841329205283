/* src/components/ErrorBoundary.module.css */

.errorBoundary {
    padding: 20px;
    text-align: center;
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
    border-radius: 4px;
    margin: 20px;
  }
  
/* src/components/UserManagement.module.css */

.container {
    background-color: #f5f5f5;
    padding: 30px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    /* max-width: 900px; */
    margin: 20px auto;
    position: relative;
    transition: transform var(--transition-duration) ease;
}

/* Wrapper for the table to limit its height and enable scrolling */
.tableWrapper {
    max-height: 500px; /* Adjust as needed */
    overflow-y: auto;
    border-radius: var(--border-radius);
}

/* Styling for the table */
.userTable {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: var(--border-radius);
    overflow: hidden; /* To apply border-radius to table */
    box-shadow: var(--box-shadow);
}

/* Table headers and cells */
.userTable th, .userTable td {
    padding: 12px 15px;
    text-align: left;
    vertical-align: top; /* Ensures content starts at the top */
    word-wrap: break-word;
    /* height: 20px; Sets fixed height for each cell */
}

/* Table header styling */
.userTable th {
    background-color: var(--fourth-color);
    border-color: var(--primary-color);
    color: #ffffff;
    font-weight: bold;
    font-size: 1rem;
}

/* Zebra striping for table rows */
.userTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Hover effect for table rows */
.userTable tr:hover {
    background-color: #f1f1f1;
}

/* Button to change user roles */
.changeRoleButton {
    background-color: var(--third-color); /* Ensure this CSS variable is defined */
    color: #ffffff;
    border: none;
    padding: 6px 14px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 0.9rem;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
    max-height: 400px;
}

.changeRoleButton:hover {
    background-color: var(--fourth-color); /* Darker shade for hover */
    transform: scale(1.05);
}

/* Button to manage project assignments */
.assignProjectButton {
    background-color: var(--third-color); /* Define this variable as needed */
    color: #ffffff;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 0.9rem;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
    max-height: 400px;
}

.assignProjectButton:hover {
    background-color: var(--fourth-color); /* Adjust hover color as needed */
    transform: scale(1.05);
}

/* Assigned Projects Cell */
.assignedProjectsCell {
    height: 50px; /* Matches the row height */
    /* max-width: 150px; */
    overflow: hidden; /* Prevents cell from expanding */
    padding-right: 10px; /* Adds some spacing to prevent scrollbar from overlapping text */
}

/* Assigned Projects Content */
.assignedProjectsContent {
    max-height: 50px; /* Ensures content doesn't exceed cell height */
    overflow-y: auto; /* Enables vertical scrollbar when content overflows */
    white-space: normal; /* Allows text to wrap to the next line */
}

/* Custom Scrollbar for Assigned Projects */
.assignedProjectsCell::-webkit-scrollbar {
    width: 6px;
}

.assignedProjectsCell::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.assignedProjectsCell::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 3px;
}

.assignedProjectsCell::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 30px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    z-index: 1000;
    width: 90%;
    max-width: 600px; /* Increased width for better project list display */
    animation: fadeIn var(--transition-duration) ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.modalContent {
    display: flex;
    flex-direction: column;
}

.modalTitle {
    margin-bottom: 15px;
    color: var(--primary-color);
}

.modalText {
    margin-bottom: 20px;
    color: var(--text-color);
}

.modalButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

/* Confirm Button */
.confirmButton {
    background-color: var(--success-color); /* Define this variable */
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration) ease;
}

.confirmButton:hover {
    background-color: #28a745; /* Fallback color if darken is not available */
}

/* Cancel Button */
.cancelButton {
    background-color: var(--danger-color); /* Define this variable */
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration) ease;
}

.cancelButton:hover {
    background-color: #dc3545; /* Fallback color */
}

/* Close Button */
.closeButton {
    background-color: var(--third-color); /* Define this variable */
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration) ease;
}

.closeButton:hover {
    background-color: var(--fourth-color); /* Fallback color */
}

/* Project Management Modal Styles */
.projectSections {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.projectSection {
    width: 45%;
}

.projectList {
    height: 200px; /* Fixed height regardless of content */
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.projectItem {
    margin-bottom: 8px;
}

.assignButton, .unassignButton {
    background-color: var(--success-color); /* Define this variable */
    color: #ffffff;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 0.9rem;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
    margin-top: 10px;
}

.assignButton:hover {
    background-color: #28a745; /* Fallback color */
    transform: scale(1.05);
}

.unassignButton {
    background-color: var(--danger-color); /* Define this variable */
}

.unassignButton:hover {
    background-color: #dc3545; /* Fallback color */
    transform: scale(1.05);
}

/* Scrollbar Styling for Project Lists */
.projectList::-webkit-scrollbar {
    width: 6px;
}

.projectList::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.projectList::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 3px;
}

.projectList::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* Responsive Design */
@media (max-width: 768px) {
    .userTable th, .userTable td {
        padding: 8px;
    }

    .modal {
        padding: 20px;
    }

    .confirmButton, .cancelButton, .closeButton, .assignButton, .unassignButton {
        padding: 6px 12px;
        font-size: 0.8rem;
    }

    .projectSections {
        flex-direction: column;
    }

    .projectSection {
        width: 100%;
        margin-bottom: 20px;
    }

    .assignedProjectsCell {
        max-height: 80px; /* Reduced height for smaller screens */
    }

    .projectList {
        height: 150px; /* Reduced height for smaller screens */
    }
}

.status {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
    font-weight: 500;
}

.verified {
    background-color: #e6ffe6;
    color: #008000;
}

.unverified {
    background-color: #fff0f0;
    color: #ff0000;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 100px auto;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.title {
    text-align: center;
    margin-bottom: 30px;
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: bold;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.formGroup label {
    font-weight: 600;
    color: #374151;
    font-size: 0.9rem;
}

.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.inputIcon {
    position: absolute;
    left: 12px;
    color: #6B7280;
    font-size: 1rem;
}

.input {
    width: 100%;
    padding: 12px 40px;
    border: 1px solid #E5E7EB;
    border-radius: var(--border-radius);
    font-size: 1rem;
    transition: all 0.2s ease;
    background-color: #F9FAFB;
}

.input:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
    background-color: #ffffff;
}

.helpText {
    color: #6B7280;
    font-size: 0.875rem;
    margin-top: 4px;
}

.submitButton {
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
}

.submitButton:hover:not(:disabled) {
    background-color: var(--secondary-color);
    transform: translateY(-2px);
}

.submitButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: #9CA3AF;
}

.loading {
    position: relative;
    cursor: wait;
}

.loading::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.errorMessage {
    color: var(--danger-color);
    text-align: center;
    font-weight: 600;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: rgba(var(--danger-color-rgb), 0.1);
    margin-bottom: 20px;
    animation: shake 0.5s ease-in-out;
}

.successMessage {
    color: #059669;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: rgba(5, 150, 105, 0.1);
    margin-bottom: 20px;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
}

.loginLink {
    text-align: center;
    margin-top: 1.5rem;
    color: #6B7280;
    font-size: 0.9rem;
}

.loginLink a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 600;
    transition: color 0.2s ease;
}

.loginLink a:hover {
    color: var(--secondary-color);
    text-decoration: underline;
}

.verificationMessage {
    text-align: center;
    padding: 2rem;
    background-color: #F9FAFB;
    border-radius: var(--border-radius);
    border: 1px solid #E5E7EB;
}

.verificationIcon {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.verificationMessage h3 {
    color: #374151;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.verificationMessage p {
    color: #6B7280;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.verificationMessage strong {
    color: #374151;
}

.resendLink {
    margin-top: 1.5rem;
    font-size: 0.9rem;
}

.resendButton {
    background: none;
    border: none;
    color: var(--primary-color);
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s ease;
}

.resendButton:hover:not(:disabled) {
    color: var(--secondary-color);
    text-decoration: underline;
}

.resendButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
} 
/* src/styles/global.css */

/* CSS Variables for Consistent Theming */
:root {
    --primary-color: #871724;
    --secondary-color: #a22d3b;
    --third-color: #5f5e5e;
    --fourth-color: #969696;
    --text-color: #4c4c4c;
    --danger-color: #000000;
    --success-color: #0e95ce;
    /* --background-color: #f5f5f5; */
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --border-radius: 8px;
    --box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    --transition-duration: 0.3s;
}

/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: border-box;
}

/* Reset default margins and paddings */
html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: var(--font-family);
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.6;
    /* Background Image */
    background-image: url('../assets/Wallpaper_bw.webp');
    background-size: cover; /* Ensures the image covers the entire viewport */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center center; /* Centers the image */
    
    /* Enhancements */
    background-attachment: fixed; /* Optional: creates a parallax effect */
    background-blend-mode: overlay; /* Optional: blends with background color for better readability */
}

a {
    text-decoration: none;
    color: var(--primary-color);
    transition: color var(--transition-duration) ease;
}

h2, h3, h4 {
    margin-bottom: 20px;
    color: var(--primary-color);
}

button {
    font-family: var(--font-family);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color var(--transition-duration) ease, color var(--transition-duration) ease;
}

/* Utility Classes */
.error {
    color: var(--danger-color);
    margin-bottom: 20px;
    font-weight: bold;
}

.success {
    color: var(--success-color);
    margin-bottom: 20px;
    font-weight: bold;
}

.hidden {
    display: none;
}

@media (max-width: 768px) {
    body {
        background-attachment: scroll; /* Disable fixed background on mobile for better performance */
    }
}

/* frontend/src/features/projects/sgam/styles/global.css */

/* Legend Styles */
.info.legend {
    line-height: 18px;
    color: #555;
    background-color: rgba(255, 255, 255, 0.8); /* White with 80% opacity */
    padding: 6px 8px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.info.legend h4 {
    margin: 0 0 5px;
    font-weight: bold;
    color: #333; /* Darker text for better readability */
}

.info.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
    border: 1px solid #ccc;
    background: #fff; /* Fallback background */
    box-shadow: 0 0 2px rgba(0,0,0,0.3);
}
/* Popup Container */
.project-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.project-info-popup {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.2);
    padding: 24px;
    width: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    margin: 20px;
    z-index: 1001;
}

.close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
  
.close-button:hover {
    background-color: #f0f0f0;
}

.info-section {
    margin-bottom: 24px;
}
  
.info-section h4 {
    color: #2c3e50;
    margin-bottom: 12px;
    font-size: 1.1em;
}
  
.info-section p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 12px;
}
  
.risk-list, .structure-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.risk-list li, .structure-list li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 0.95em;
    color: #4a4a4a;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .project-info-popup {
        width: 90%;
        max-width: 800px;
    }
}

@media (max-width: 768px) {
    .project-info-popup {
        width: 95%;
        margin: 10px;
        padding: 16px;
    }
}

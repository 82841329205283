/* .container{
    max-width: 600;
} */
/* Header Section */
.header {
    display: flex;
    justify-content: space-between; /* Ensure title and logout button are spaced apart */
    align-items: center; /* Aligns title and logout button vertically */
    margin-bottom: 20px;
}

.title {
    font-size: 2rem;
    color: var(--primary-color);
    margin: 0;
}

/* Updated Logout Button */
.logoutButton {
    background-color: var(--third-color);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration) ease;
    margin-left: auto; /* Ensures logout button stays on the right */
}

.logoutButton:hover {
    background-color: var(--fourth-color); /* Adjusted hover color for better contrast */
}

/* Navigation Section */
.nav {
    margin-top: 40px;
    margin-bottom: 20px;
}

.nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.navLink {
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    padding: 10px 25px;
    border-radius: var(--border-radius);
    cursor: pointer;
    text-align: center;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
}

.navLink:hover {
    background-color: var(--secondary-color); /* Adjusted hover color for better contrast */
    transform: translateY(-5px);
}

.backButton {
    background-color: var(--third-color);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color var(--transition-duration) ease;
}

.backButton:hover {
    background-color: var(--fourth-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 20px;
    }

    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .logoutButton {
        width: 100%;
        margin-top: 10px;
        margin-left: 0; /* Reset margin to avoid shifting on small screens */
    }

    .nav ul {
        flex-direction: column;
        align-items: center;
    }

    .navLink {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .navLink {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .logoutButton {
        padding: 8px 16px;
        font-size: 0.9rem;
        width: 100%;
    }
}

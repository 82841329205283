/* src/components/ProjectCreation.module.css */

.container {
    background-color: #f5f5f5;
    padding: 30px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    max-width: 800px; /* Increased width to accommodate the table */
    margin: 0 auto;
    min-width: 300px;
}

.message {
    margin-bottom: 20px;
    color: var(--success-color);
    font-weight: bold;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formGroup label {
    margin-bottom: 5px;
    font-weight: bold;
}

.select, .input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    font-size: 1rem;
    transition: border-color var(--transition-duration) ease;
}

.select:focus, .input:focus {
    border-color: var(--primary-color);
    outline: none;
}

.submitButton {
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 1rem;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
    width: 50%;
    align-self: center;
    margin-bottom: 2rem;
}

.submitButton:hover {
    background-color: var(--secondary-color); /* Adjusted hover color for better contrast */
    transform: translateY(-5px);
}

/* Projects Table Styles */
.projectTableContainer {
    margin-top: 40px;
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
}

.projectTable {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: var(--border-radius);
    overflow: hidden; /* To apply border-radius to table */
    box-shadow: var(--box-shadow);
}

.projectTable th, .projectTable td {
    padding: 12px 15px;
    text-align: left;
}

.projectTable th {
    background-color: var(--fourth-color);
    border-color: var(--primary-color);
    color: #ffffff;
    font-weight: bold;
    font-size: 1rem;
}

.projectTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.projectTable tr:hover {
    background-color: #f1f1f1;
}

.deleteButton {
    background-color: var(--third-color); /* Ensure this CSS variable is defined */
    color: #ffffff;
    border: none;
    padding: 3px 14px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 0.9rem;
    transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
}

.deleteButton:hover {
    background-color: var(--fourth-color); /* Darker shade for hover */
    transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 20px;
        max-width: 100%; /* Allow container to take full width on smaller screens */
    }

    .submitButton {
        width: 100%;
    }

    .projectTable th, .projectTable td {
        padding: 10px;
        font-size: 0.9rem;
    }

    .deleteButton {
        padding: 6px 10px;
        font-size: 0.8rem;
    }
}

ul {
  list-style: none; /* Removes the default bullet points */
  padding: 0;       /* Removes default padding */
  margin: 0;        /* Removes default margin */
}

li {
  margin: 0;        /* Optional: Remove any margin between list items */
  padding: 0;       /* Optional: Remove any padding within list items */
}

/* Popup Container */
.popup-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  padding: 16px;
  font-family: 'Roboto', Arial, sans-serif;
  max-width: 320px;
  overflow: hidden;
}

/* Header Section */
.popup-header h3 {
  margin: 0;
  font-size: 1.4em;
  color: #2c3e50;
  padding-bottom: 4px;
}

/* Description Section */
.popup-description p {
  margin: 8px 0;
  font-size: 0.95em;
  color: #6c757d;
  line-height: 1.5;
}

/* Features Section */
.popup-features {
  margin-top: 12px;
}

/* Individual Feature Items */
.feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #f8f9fa;
}

/* Highlighted Feature */
.feature-highlighted {
  background-color: #e8f4ff;
  border: 1px solid #cce5ff;
}

.feature-highlighted .feature-name {
  color: #0056b3;
  font-weight: 600;
}

/* Feature Name */
.feature-name {
  font-weight: 500;
  color: #2c3e50;
  flex: 1;
  margin-right: 12px;
}

.hazard-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* Hazard Level */
.hazard-level {
  padding:1px 6px;
  border-radius: 4px;
  font-size: 0.85em;
  font-weight: 500;
  text-align: center;
  min-width: 65px;
  display: inline-block;
  margin-right: -10px;
  /* margin-left: auto; */
  margin-top: 2px;
}

.hazard-name {
  font-weight: 500;
  color: #2c3e50;
  flex: 1;
  margin-left: 40px;
  align-self: left;
  padding: 0px 0px;
  /* margin-left:10px; */
}

.location-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.landslide-icon {
  margin-right: 5px;
}

.location-icon {
  margin-right: 5px;
}

.warning-icon {
  margin-right: 5px;
}

.info-button {
  margin: 0;
  border: none;
  background-color: transparent;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: super; /* Aligns the button as superscript */
  font-size: 0.8em; /* Makes the button smaller */
}

.info-icon {
  margin: 0;
  width: 10px;
  height: 10px;
}

.info-button:hover {
  opacity: 0.5;
}

.coordinates {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #333;
  margin-top: -10px;
}

.mainrisk {
  margin-right:auto;
  font-size: 12px;
  color: #333;
  margin-top: 0px;
  align-items: left;
}

.risk-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.divider {
  text-align: center;
  margin: 10px 0;
  color: #888;
}



/* Responsive Adjustments */
@media (max-width: 500px) {
  .popup-container {
    padding: 12px;
    width: 95%;
  }

  .popup-header h3 {
    font-size: 1.2em;
  }

  .coordinates {
    font-size: 0.85em;
  }

  .hazard-level {
    font-size: 0.8em;
    min-width: 60px;
    padding: 4px 8px;
  }

  .project-info-popup {
    padding: 16px;
    width: 95%;
  }
}

/* src/styles/SettingsPopup.module.css */

.popupContainer {
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50px; /* Adjust based on the settings button position */
  left: 50px; /* Adjust based on the settings button position */
  z-index: 1000; /* Ensure it appears above other elements */
}

.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.closeButton {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.closeButton:hover {
  color: #f00;
}

/* src/features/projects/sgam/styles/FeatureSelectionPopup.module.css */

.popupContent {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 15px;
  width: 240px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.select {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.structureFilters {
  margin-top: 10px;
}

.structureFilters h4 {
  margin-bottom: 10px;
  font-size: 1rem;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.checkboxLabel input {
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid #ccc;
  /* appearance: none; */
  background-color: #f9f9f9;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="none" stroke="%23333" stroke-width="2" d="M1 1l4 3 4-3"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  cursor: pointer;
}

.select:focus {
  outline: none;
  border-color: #3097d3;;
}

.imageToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3097d3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3097d3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Label Styling */
.toggleLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  user-select: none;
  margin-bottom: 8px; /* Space between toggles */
}

.toggleLabelText {
  margin-left: 10px;
  font-size: 0.9rem; /* Slightly smaller text for smaller toggles */
}

/* Small Toggle Switch for Structure Filters */
.smallSwitch {
  width: 30px; /* Reduced width */
  height: 16px; /* Reduced height */
}

.smallSwitch .slider {
  border-radius: 16px;
}

.smallSwitch .slider:before {
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
}

.smallSwitch input:checked + .slider:before {
  transform: translateX(14px); /* Adjust based on reduced width */
}

.smallSwitch .slider {
  transition: 0.4s;
}

/* Ensure alignment and spacing for smaller toggles */
/* .toggleLabel and .toggleLabelText already defined above */

/* src/styles/BasemapDropdown.module.css */

.basemapDropdown {
    margin-bottom: 16px;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  